import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    susono: true;
    hokuei: true;
  }
}


// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    warning:{
      main: '#F59C55',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: '#F2F7F8',
          borderRadius: 0,
          '&:before': {
            borderBottom: 'none !important',
          },
          '&:after': {
            borderBottom: 'none !important',
          },
        },
        input:{
          fontSize: 16,
          paddingTop: '12px',
          paddingBottom: '10px',
        },
        inputMultiline: {
          paddingTop: '0px',
        },
      },
    },
  },
});

export default theme;
